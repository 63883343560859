import { Controller } from '@hotwired/stimulus'
import { useClassToggle } from '~/shared/controllers/mixins/use_class_toggle'

export default class extends Controller {
  static targets = ['toggleable']
  static classes = ['toggle']

  toggleableTargets: HTMLElement[]
  toggleClass: string

  connect(): void {
    useClassToggle(this)
  }

  toggle(e: Event): void {
    e.preventDefault()
    this.toggleToggleClasses()
  }

  // TODO: fix with simple toggle with preventDefault setting value
  pureToggle() {
    this.toggleToggleClasses()
  }

  add(e): void {
    e.preventDefault()
    this.addToggleClasses()
  }

  remove(e): void {
    e.preventDefault()
    this.removeToggleClasses()
  }
}
