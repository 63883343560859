import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['removeableAlert']
  static classes = ['animate']

  removeableAlertTargets: HTMLElement[]
  animateClass: string

  timeout: number | null = null;

  removeableAlertTargetConnected(e) {
    this.displayNext(e)
  }

  dismiss(e) {
    if (!(e.target instanceof HTMLAnchorElement)) {
      this.removeableAlertTargets[0].remove()
    }
      clearTimeout(this.timeout);
      this.timeout = null;
      this.displayNext(e);
  }

  displayNext(e) {
    if (this.hasRemoveableAlertTarget && !this.removeableAlertTargets[0].classList.contains(this.animateClass)) {
      this.removeableAlertTargets[0].classList.add(this.animateClass);

      var lifetimeInSeconds = 5

      if (this.removeableAlertTargets[0].dataset.durationInSeconds != null) {
        lifetimeInSeconds = (this.removeableAlertTargets[0].dataset.durationInSeconds as unknown) as number
      }
      
      this.timeout = setTimeout(() => this.dismiss(e), lifetimeInSeconds * 1000);
    }
  }
}
