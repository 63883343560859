import { Controller } from "@hotwired/stimulus"
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static values = {
    newTab: { type: Boolean, default: false }
  }

  newTabValue: boolean
  boundSubmitEnd: EventListener

  connect(): void {
    this.boundSubmitEnd = this.submitEnd.bind(this)

    document.addEventListener('turbo:submit-end', this.boundSubmitEnd)
  }

  disconnect(): void {
    document.removeEventListener('turbo:submit-end', this.boundSubmitEnd)
  }

  submitEnd(event): void {
    if (typeof (event.detail.fetchResponse) !== 'undefined') {
      const redirectLocation = event.detail.fetchResponse.response.headers.get('location')

      if (!redirectLocation) {
        return
      }

      event.preventDefault()

      if (this.newTabValue) {
        window.open(redirectLocation, '_blank').focus()
      } else {
        Turbo.clearCache()
        Turbo.visit(redirectLocation)
      }
    }
  }
}
