import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['timer']
  static values = {
    duration: Number
  }

  durationValue: number
  driftValue: number

  connect() {
    this.setTimer(this.durationValue)
  }

  setTimer(timeInSeconds: number) {
    var endDate = Date.now() + (timeInSeconds * 1000)
    var intervalId = setInterval(() => this.displayTimer(endDate), 500)

    setTimeout(() => clearInterval(intervalId), timeInSeconds * 1000)
  }

  displayTimer(endDate: number) {
    var currentDate = Date.now()
    var deltaTime = endDate - currentDate

    if (this.hasTimerTarget) {
      if (deltaTime / 1000 >= 0) {
        this.timerTarget.innerHTML = (Math.floor(deltaTime / 1000) as unknown) as string
      }
    }
  }
}