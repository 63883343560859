import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleable']
  static classes = ['toggle']
  static values = {
    addDelay: { type: Number, default: 0 },
    removeDelay: { type: Number, default: 0 },
    onConnect: { type: Boolean, default: false }
  }

  toggleableTargets: HTMLElement[]
  toggleClasses: string[]
  addDelayValue: number
  removeDelayValue: number
  onConnectValue: boolean

  connect() {
    if (this.onConnectValue) {
      this.toggle()
    }
  }

  toggle(): void {
    this.toggleableTargets.forEach(toggleableTarget => {
      this.toggleClasses.forEach(toggleClass => {
        const delay = toggleableTarget.classList.contains(toggleClass) ? this.removeDelayValue : this.addDelayValue

        if (delay > 0) {
          setTimeout(() => toggleableTarget.classList.toggle(toggleClass), delay)
        } else {
          toggleableTarget.classList.toggle(toggleClass)
        }
      })
    })
  }
}
