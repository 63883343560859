import { Controller } from '@hotwired/stimulus'

// <div data-controller="attribute"
//      data-attribute-name-value="disabled"
// 	    data-attribute-value-value="">
// 	 <input type="radio" data-action="change->attribute#toggleWithDefault" value="1" />
// 	 <input type="radio"
//          data-attribute-selector="#dob"
//          data-attribute-name="value"
//          data-attribute-value="2021-12-30"
// 		      data-action="change->attribute#toggleWithDefault"
//          value="2" />
//
// 	 <input type="date" id="dob" data-attribute-target="attribute">
// </div>
export default class extends Controller {
  static targets = ['attribute']
  static values = {
    name: String,
    value: String,
  }

  readonly attributeTargets: HTMLElement[]

  readonly nameValue: string
  readonly valueValue: string

  add(e: Event) {
    this.toggle(e, true)
  }

  remove(e: Event) {
    this.toggle(e, false)
  }

  toggle(e: Event, force = undefined) {
    e.preventDefault()

    this.toggleWithDefault(e, force)
  }

  toggleWithDefault(e: Event, force = undefined) {
    this.toggleAttribute(e.currentTarget as HTMLElement, force)
  }

  toggleAttribute(initiator: HTMLElement, force = undefined) {
    const name = initiator.dataset.attributeName || this.nameValue,
      value = initiator.dataset.attributeValue || this.valueValue || '',
      selector = initiator.dataset.attributeSelector,
      elements = selector
        ? Array.from<HTMLElement>(this.element.querySelectorAll(selector))
        : this.attributeTargets,
      anyAdded = elements.reduce((added, element) => {
        added = added || element.toggleAttribute(name, force)
        if (element.hasAttribute(name)) {
          element.setAttribute(name, value)
        }
        return added
      }, false)

    return anyAdded
  }
}
