import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['removeable']

  removeableTargets: HTMLElement[]

  remove(e) {
    e.preventDefault()
    this.removeableTargets.forEach(element => element.remove())
  }
}
