import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

import Request from '~/shared/request'

export default class extends Controller {
  static debounces = ['setValidationCheck']
  static failedValidations = []
  static targets = ['input', 'confirmationInput', 'errorImage']
  static values = {
    checkIcon: String,
    clearIcon: String,
    url: String,
    formId: String,
  }

  inputTarget: HTMLInputElement
  confirmationInputTarget: HTMLInputElement
  errorImageTargets: HTMLImageElement[]
  checkIconValue: string
  clearIconValue: string
  urlValue: string
  formIdValue: string
  failedValidations: any[]

  connect() {
    useDebounce(this, { wait: 500 })
  }

  validateInputPassword() {
    // Initially invalid until proven otherwise
    this.inputTarget.setCustomValidity('false')
    this.triggerInputValidate()

    this.failedValidations = new Array(9).fill(this.clearIconValue)

    if (this.inputTarget.value.length >= 8)
      this.failedValidations[0] = this.checkIconValue

    if (this.inputTarget.value.match(/[^A-Za-z0-9_]/))
      this.failedValidations[1] = this.checkIconValue

    if (this.inputTarget.value.match(/[0-9]/))
      this.failedValidations[2] = this.checkIconValue

    if (this.inputTarget.value.match(/[A-Z]/))
      this.failedValidations[3] = this.checkIconValue

    if (this.inputTarget.value.match(/[a-z]/))
      this.failedValidations[4] = this.checkIconValue

    if (this.inputTarget.value == this.confirmationInputTarget.value)
      this.failedValidations[8] = this.checkIconValue

    // Need to reduce ajax calls
    this.setValidationCheck()
  }

  async setValidationCheck() {
    var formData = new FormData(
      document.getElementById(this.formIdValue) as HTMLFormElement
    )

    formData.set('_method', 'post')

    try {
      const { response } = await Request.fetch(this.urlValue, {
        method: 'post',
        body: formData,
      })
      const { errors = [] } = await response.json()

      Object.entries({
        'Password password_strength': 5,
        'Password last_10_error': 6,
        'Password duration_error': 7,
      }).forEach(([error, failedValidationIndex]) => {
        if (!errors.includes(error))
          this.failedValidations[failedValidationIndex] = this.checkIconValue
      })
    } finally {
      this.inputTarget.setCustomValidity('')
      this.confirmationInputTarget.setCustomValidity('')

      this.failedValidations.forEach((option, index) => {
        this.errorImageTargets[index].src = option

        if (option == this.clearIconValue) {
          this.inputTarget.setCustomValidity('false')
        }
      })

      // Other controllers might depend on changes made here and since this
      // call is debounced we need to ensure they are triggered once this is
      // done.
      this.triggerInputValidate()
    }
  }

  triggerInputValidate() {
    this.inputTarget.dispatchEvent(new Event('validate'))
  }
}
