import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['disableable']

  disableableTargets: HTMLFormElement

  enable(e){
    this.disableableTargets.forEach((element) => {
      element.disabled = false
    })
  }

  disable(e){
    this.disableableTargets.forEach((element) => {
      element.disabled = true
    })
  }

  toggle(e) {
    if (!!e.currentTarget.value && !!e.currentTarget.value.trim()) {
      this.enable(e)
    } else {
      this.disable(e)
    }
  }
}
