import { Controller } from '@hotwired/stimulus'
import { navigator } from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['submit']
  static classes = ['busy']
  static values = {
    busyText: String,
    initValidate: {
      type: Boolean,
      default: true
    }
  }

  readonly submitTarget: HTMLButtonElement
  readonly hasSubmitTarget: boolean
  readonly hasBusyClass: boolean
  readonly submitTargets: HTMLElement[]
  readonly busyClass: string
  readonly busyTextValue: string
  readonly initValidateValue: boolean

  connect() {
    if (this.initValidateValue) this.validate()
  }

  disableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTargets.forEach((submit: HTMLButtonElement) => {
        submit.disabled = true
        if (this.hasBusyClass) submit.classList.add(this.busyClass)
        submit.innerHTML = this.busySubmitText
      })
    }
  }

  submitInvalid() {
    this.element.setAttribute("novalidate", "")
  }

  submit() {
    navigator.submitForm(this.element as HTMLFormElement)
  }

  validate() {
    if (this.hasSubmitTarget) {
      this.submitTargets.forEach((submit: HTMLButtonElement) => {
        submit.disabled = !this.isValid

        if (this.hasBusyClass) submit.classList.remove(this.busyClass)
      })
    }
  }

  get isValid() {
    return !this.hasInvalidInputs()
  }

  get busySubmitText(): string {
    return this.busyTextValue || 'Almost there!'
  }

  hasInvalidInputs(): boolean {
    return this.element.querySelector('input:invalid:not([disabled])') != null
  }
}
