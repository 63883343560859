export const useClassToggle = (controller) => {
  Object.assign(controller, {
    removeToggleClass() {
      this.proccessClassAction(this.toggleClass, this.toggleableTargets, 'remove')
    },

    addToggleClass() {
      this.proccessClassAction(this.toggleClass, this.toggleableTargets, 'add')
    },

    toggleToggleClass() {
      this.proccessClassAction(this.toggleClass, this.toggleableTargets)
    },

    removeToggleClasses() {
      this.proccessClassAction(this.toggleClasses, this.toggleableTargets, 'remove')
    },

    addToggleClasses() {
      this.proccessClassAction(this.toggleClasses, this.toggleableTargets, 'add')
    },

    toggleToggleClasses() {
      this.proccessClassAction(this.toggleClasses, this.toggleableTargets)
    },

    proccessClassAction(classNames, targets, action = 'toggle') {
      targets.forEach((element) => {
        if (typeof classNames === 'object') {
          classNames.forEach(className => {
            element.classList[action](className)
          })
        } else {
          element.classList[action](classNames)
        }
      })
    }
  })
}
