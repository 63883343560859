import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radio', 'checkbox']
  static classes = ['highlight']

  radioTargets: HTMLInputElement[]
  checkboxTargets: HTMLInputElement[]
  itemIndex: number
  readonly highlightClass: string

  tabRadio(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.itemIndex === undefined) {
      this.itemIndex = 0
      this.selectRadio(e)
    } else if (e.key === 'ArrowDown' && this.itemIndex < (this.radioTargets.length - 1)) {
      this.itemIndex++
      this.selectRadio(e)
    } else if (e.key === 'ArrowUp' && this.itemIndex > 0) {
      this.itemIndex--
      this.selectRadio(e)
    }
  }

  tabCheckbox(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.itemIndex === undefined) {
      this.itemIndex = 0
      this.highlightCheckbox(e)
    } else if (e.key === 'ArrowDown' && this.itemIndex < (this.checkboxTargets.length - 1)) {
      this.removeBorder(e)
      this.itemIndex++
      this.highlightCheckbox(e)
    } else if (e.key === 'ArrowUp' && this.itemIndex > 0) {
      this.removeBorder(e)
      this.itemIndex--
      this.highlightCheckbox(e)
    } else if (e.key === ' ') {
      e.preventDefault()
      this.checkboxTargets[this.itemIndex].checked = !this.checkboxTargets[this.itemIndex].checked
      this.checkboxTargets[this.itemIndex].dispatchEvent(new Event('input'))
    }
  }

  selectRadio(e) {
    this.radioTargets[this.itemIndex].checked = true
    this.radioTargets[this.itemIndex].dispatchEvent(new Event('input'))
    e.preventDefault()
    this.radioTargets[this.itemIndex].parentElement.scrollIntoView()
  }

  highlightCheckbox(e) {
    this.checkboxTargets[this.itemIndex].parentElement.classList.add(this.highlightClass)
    e.preventDefault()
    this.checkboxTargets[this.itemIndex].parentElement.scrollIntoView()
  }

  removeBorder(e) {
    this.checkboxTargets[this.itemIndex].parentElement.classList.remove(this.highlightClass)
  }
}
