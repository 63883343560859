import { FetchRequest } from '@rails/request.js'

export default class {
  // Arguments are similar to the Fetch API
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options
  // and check https://github.com/rails/request.js#how-to-use for more details
  static fetch(url: string, options: RequestInit) {
    const request = new FetchRequest(options['method'], url, options)

    return request.perform()
  }
}
